import Head from 'next/head';
import SvgLogoName from 'assets/brand/logo-full-name.svg';

export default function MaintenancePage() {
	return (
		<>
			<Head>
				<title>Experilyze - Under Construction</title>
				<meta name="description" content="Our site is currently under maintenance. We'll be back soon!" />
			</Head>
			<main style={{ 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                flexDirection: 'column',
                textAlign: 'center', 
                padding: '50px',
            }}>
				<SvgLogoName />
				<h1 style={{
                    fontSize: '24px'
                }}>
                    Under Construction
                </h1>
				<p>We're currently working on something exciting. Please check back later.</p>
                <p>
                    <a href="mailto:info@experilyze.com" style={{
                        display: 'flex',
                        justifyContent: 'center',
                        maxWidth: '120px',
                        background: '#000',
                        color: '#fff',
                        padding: '.5rem .75rem',
                        borderRadius: '4px',
                        fontSize: '14px',
                        margin: '1rem',
                    }}>Contact Us</a>
                </p>
			</main>
		</>
	);
};

import 'styles/globals.scss';
// import { RecoilRoot } from 'recoil';
import { useEffect, useState } from 'react';
import AppWrapper from 'components/AppWrapper';
import Layout from 'components/Layout';
import MaintenancePage from './maintenance';
import ErrorPage from 'next/error'

export default function MyApp({ Component, pageProps, status }) {
    const [isMaintenance, setIsMaintenance] = useState(false);
    
    useEffect(() => {
        setIsMaintenance(process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true');
    }, []);

    if (isMaintenance) {
        return <MaintenancePage />;
    }

  	return (
		<>
			{ !status ? (
				<>
					{/* <RecoilRoot> */}
						<AppWrapper />
						<Layout>
							<Component {...pageProps} />
						</Layout>
					{/* </RecoilRoot> */}
				</>
			) : (
				<ErrorPage statusCode={404} />
			) } 
		</>
 	)
}

import { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import styles from 'styles/Navbar.module.scss';
import SvgLogoName from 'assets/brand/logo-full-name.svg';

export default function Navbar() {
	const [open, setOpen] = useState(false);
	const [isNavbarInView, setIsNavbarInView] = useState(false);
	const navbarRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => {
			setIsNavbarInView(!entry.isIntersecting);
		}, { 
			root: null,
			rootMargin: '0px',
			threshold: 0.5
		})
		if (navbarRef.current) {
			observer.observe(navbarRef.current);
		}
		return () => {
			observer.disconnect();
		}
	}, [])

	const handleOpen = () => {
		setOpen(prev => !prev);
	}

	const handleCloseMenu = () => {
		setOpen(false);
	}
	
	return (
		<>
			<nav ref={ navbarRef }  className={ styles.navbar } >
				<div >
					<ul aria-expanded={ open }>
						<li>
                            <Link href='/' onClick={ handleCloseMenu }><SvgLogoName /></Link>
						</li>
						<li>
							<Link href='/#features' onClick={ handleCloseMenu }>Features</Link>
						</li>
						<li>
							<Link href='/#plans' onClick={ handleCloseMenu }>Plans</Link>
						</li>
						<li>
							<Link href='/#faq' onClick={ handleCloseMenu }>FAQ</Link>
						</li>
						<li>
							<Link href='/contact' onClick={ handleCloseMenu }>Contact</Link>
						</li>
						<li>
							<Link href='/contact' className='btn-cta' onClick={ handleCloseMenu }>Sign In</Link>
						</li>
					</ul>
				</div>
				<button onClick={ handleOpen } className='nav-menu-btn' id='nav-menu' role='button' title='Menu Toggle' aria-expanded={ open }>
					<span><span></span></span>
				</button>
			</nav>
		</>
	)
}

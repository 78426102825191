import styles from 'styles/Footer.module.scss';
import Link from 'next/link';

export default function Footer() {

	return (
		<footer className={ styles.footer }>
			<section className={ styles.container }>
				<div>
					<p>Experilyze</p>
					<hr />
					<ul>
						<li>
							<Link href='mailto:info@experilyze.com'>info@experilyze.com</Link>
						</li>
					</ul>
				</div>
				<div>
					<p>Navigation</p>
					<hr />
					<ul>
						<li className={ 'navLink' }>
							<Link href='/'>Home</Link>
						</li>
						<li className={ 'navLink' }>
							<Link href='/#features'>Features</Link>
						</li>
						<li className={ 'navLink' }>
							<Link href='/#plans'>Plans</Link>
						</li>
						<li className={ 'navLink' }>
							<Link href='/#faq'>FAQ</Link>
						</li>
						<li className={ 'navLink' }>
							<Link href='/contact'>Contact</Link>
						</li>
					</ul>
				</div>
				<div>
					<p>Services</p>
					<hr />
					<ul>
						<li>AI-Driven Experimentation</li>
						<li>No-Code Solution</li>
						<li>Full Integration with Existing Tools</li>
						<li>Scalable for Any Buisness Size</li>
						<li>Effortless Test Creation</li>
						<li>Smart Experiment Suggestions</li>
					</ul>
				</div>
			</section>
			<section className={ styles.copyright }>
				<div>
					Copyright © 2024 | Experilyze Inc. | All Rights Reserved
				</div>
			</section>
		</footer>
	)
}